import { convertLocale } from '@pe-libs/react-components/build/lib/helper';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { getQueryParameters } from '#helpers/query';
import { setUserIntl } from '#modules/app/actions/settings';
import { wrapper } from '../store';
import messages from './messages';

const PeRegister = dynamic(() => import('#modules/auth/components/PeRegister'), { ssr: false });

const RegisterPage: NextPage = () => {
  const router = useRouter();
  const formatMessage = useIntlMessage();

  const loadAdCellParameters = typeof router.query.bid === 'string';

  return (
    <>
      <Head>
        <title>{formatMessage(messages.seoTitle)}</title>
        <meta name="description" content={formatMessage(messages.seoDescription)} />
        {loadAdCellParameters && (
          <>
            <script type="text/javascript" src="https://t.adcell.com/js/trad.js" />
            <script>Adcell.Tracking.track();</script>
          </>
        )}
      </Head>
      <PeRegister />
    </>
  );
};

RegisterPage.getInitialProps = wrapper.getInitialPageProps((store) => async (context) => {
  const lang = getQueryParameters(context.query, 'lang');
  const locale = convertLocale(lang, 'locale');

  store.dispatch(setUserIntl({ locale }));

  return { props: {} };
});

export default RegisterPage;
